<op-autocompleter
  [model]="value"
  [openDirectly]="true"
  [getOptionsFn]="getAvailableUsers.bind(this)"
  [trackByFn]="userTracker"
  [multiple]="multiple"
  [labelForId]="labelForId"
  [appendTo]="appendTo"
  [openDirectly]="openDirectly"
  [focusDirectly]="focusDirectly"
  (change)="writeValue($event)"
  (keydown.escape)="cancel.emit()"
>
  <ng-template
    op-autocompleter-option-tmp
    let-item
    let-search="search"
  >
    <op-principal
      *ngIf="item && item.href"
      [principal]="item"
      [hideName]="true"
      size="mini"
    ></op-principal>
    <span [opSearchHighlight]="search">
      {{ item.name }}
    </span>
  </ng-template>
  <ng-template op-autocompleter-footer-tmp *ngIf="inviteUserToProject">
    <op-invite-user-button
      [projectId]="inviteUserToProject"
      class="op-select-footer"
    ></op-invite-user-button>
  </ng-template>
</op-autocompleter>

<input
  #hiddenInput
  [name]="name"
  type="hidden"
  [value]="plainValue"
  data-members-form-target="memberUserIds"
  data-action="change->members-form#triggerLimitWarningIfReached"
/>
