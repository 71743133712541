<li
  class="spot-list--item op-project-include-list--item"
  *ngFor="let project of projects"
  data-qa-selector="op-project-include-list--item"
  [attr.data-list-selector]="projectListItemIdentifier"
>
  <spot-tooltip
    class="op-project-include-list--tooltip"
    [alignment]="getTooltipAlignment(project)"
    [disabled]="!project.disabled"
  >
    <ng-container slot="trigger">
      <label
        tabindex="-1"
        class="spot-list--item-action op-project-include-list--item-action"
        (keydown.space)="changeSelected(project)"
        [ngClass]="{
          'spot-list--item-action_disabled': project.disabled,
          'spot-list--item-action_active': (searchableProjectListService.activeItemID$ | async) === project.id
        }"
        [attr.data-list-selector]="projectListActionIdentifier"
        [attr.data-list-disabled]="project.disabled || undefined"
      >
        <spot-checkbox
          [tabindex]="-1"
          [checked]="isChecked(project.href) || (includeSubprojects && parentChecked)"
          (change)="changeSelected(project)"
          [disabled]="project.disabled"
          [attr.data-qa-project-include-id]="project.id"
          [attr.data-qa-project-include-checked]="(isChecked(project.href) || (includeSubprojects && parentChecked))  ? 1 : 0"
        ></spot-checkbox>
        <div
          class="spot-list--item-title spot-list--item-title_ellipse-text"
          data-qa-selector="op-project-include-list--item-title"
          [opSearchHighlight]="searchText"
        >{{ project.name }}</div>
      </label>
    </ng-container>

    <p
      slot="body"
      class="spot-body-small"
    >
      <span *ngIf="project.href === currentProjectHref else otherMessages">{{ text.current_project }}</span>

      <ng-template #otherMessages>
        <span *ngIf="includeSubprojects && parentChecked else searchMatch">{{ text.include_all_selected }}</span>
        <ng-template #searchMatch>
          <span *ngIf="project.disabled">{{ text.does_not_match_search }}</span>
        </ng-template>
      </ng-template> 
    </p>
  </spot-tooltip>

  <ul
    *ngIf="project.children.length"
    op-project-include-list
    [projects]="project.children"
    [selected]="selected"
    [includeSubprojects]="includeSubprojects"
    [parentChecked]="parentChecked || isChecked(project.href)"
    [searchText]="searchText"
    (update)="updateList($event)"
  ></ul>
</li>
